<template>
  <handle type="hywj"></handle>
</template>

<script>
import Handle from './handle'

export default {
  components: {
    Handle
  }
}
</script>
